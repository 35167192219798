/* eslint-disable max-classes-per-file */
import { getConvertedAddressText } from '../../address/model/addressFinder'
import Api from './api'

/*
{
  "name": "강하나",
  "phone": "01012345678",
  "age": 14,
  "gender": "female",
  "weight": 103,
  "medical_information": "루마티스 관절염",
  "more_information": "식사 후 운동 필수",
  "started_at": "2021-05-14",
  "ended_at": "2021-05-16",
  "daily_payment": "13",
  "care_time_info": "24시간",
  "want_carer_gender": "male",
  "important_things_to_pick_career_idx": 1,
  "location_idx": 1,
  "location_detail_idx": 46,
  "location_type": "병원",
  "location_type_detail_1": "전북대병원",
  "location_type_detail_2": "101호"
}
*/
const PatientBasicInfoSchema = class {
  constructor(item = {}) {
    this.age = item.age || 0
    this.email = item.email || null
    this.gender = item.gender || null
    this.idx = item.idx || 0
    this.name = item.name || null
    this.password = item.password || null
    this.phone = item.phone || null
  }

  getGender() {
    let gender = 'Unknown'
    if (this.gender === 'male') {
      gender = '남'
    } else if (this.gender === 'female') {
      gender = '여'
    }
    return gender
  }
}

const PatientExtraInfoSchema = class {
  constructor(item = {}) {
    this.care_time_info = item.care_time_info || null
    this.created_at = item.created_at || null
    this.daily_payment = item.daily_payment || null
    this.ended_at = item.ended_at || null
    this.idx = item.idx || 0
    this.important_things_to_pick_career_idx = item.important_things_to_pick_career_idx || 0
    this.is_deleted = item.is_deleted || 0
    this.is_matched = item.is_matched || 0
    this.location_detail_idx = item.location_detail_idx || 0
    this.location_idx = item.location_idx || 0
    this.location_type = item.location_type || null
    this.location_type_detail_1 = item.location_type_detail_1 || null
    this.location_type_detail_2 = item.location_type_detail_2 || null
    this.medical_information = item.medical_information || null
    this.more_information = item.more_information || null
    this.started_at = item.started_at || null
    this.want_carer_gender = item.want_carer_gender || null
    this.weight = item.weight || 0
    this.is_admin = item.is_admin ?? null
  }

  async getAddress() {
    const address = await getConvertedAddressText(this.location_idx, this.location_detail_idx)
    return address
  }

  async getMatchedInfo() {
    let matchedAt = null
    if (this.is_matched === 1) {
      const { done } = await Api.getMatchedInfo(this.idx)
      if (done) {
        matchedAt = done.match_info.updated_at
      }
    }
    return matchedAt
  }

  getWantCarerGender() {
    let r = '성별무관'
    if (this.want_carer_gender === 'male') {
      r = '남성선호'
    } else if (this.want_carer_gender === 'female') {
      r = '여성선호'
    }
    return r
  }
}

export const PatientSchema = class {
  constructor(item = {}) {
    this.basic_info = new PatientBasicInfoSchema(item.basic_info || {})
    this.extra_info = new PatientExtraInfoSchema(item.extra_info || {})
  }
}

export const PatientFormSchema = class {
  constructor(item = {}) {
    this.name = item.name || ''
    this.phone = item.phone || ''
    this.age = item.age || 0
    this.gender = item.gender || ''
    this.weight = item.weight || 0
    this.medical_information = item.medical_information || ''
    this.more_information = item.more_information || ''
    this.started_at = item.started_at || ''
    this.ended_at = item.ended_at || ''
    this.daily_payment = item.daily_payment || ''
    this.care_time_info = item.care_time_info || ''
    this.want_carer_gender = item.want_carer_gender || ''
    this.important_things_to_pick_career_idx = item.important_things_to_pick_career_idx || 0
    this.location_idx = item.location_idx || 0
    this.location_detail_idx = item.location_detail_idx || 46
    this.location_type = item.location_type || ''
    this.location_type_detail_1 = item.location_type_detail_1 || ''
    this.location_type_detail_2 = item.location_type_detail_2 || ''
    this.is_admin = item.is_admin ?? false
  }
}
