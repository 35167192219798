<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="등록환자 목록" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-text-field
          v-model.trim="search"
          label="이름 & idx 입력 후 Enter"
          outlined
          dense
          :required="[]"
          hide-details="auto"
          @keydown.enter="onEnter"
        />
      </v-col>
      <v-col cols="auto">
        <v-select
          v-model="filterLocation"
          :items="locations"
          label="지역/필터"
          append-icon="expand_more"
          outlined
          dense
        />
      </v-col>
      <v-col cols="auto">
        <!-- :to="`/patients/add`" -->
        <v-btn class="mx-0" color="primary" :to="`/patients/add`">
          <v-icon>add</v-icon>
          <span class="pl-2">등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="tbl elevation-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="item in headers" :key="item.text" :class="`text-${item.align}`">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender :is-loading="isLoading" :cols="headers.length" :items-length="items.length" />
            <tbody v-if="!isLoading">
              <tr v-for="(item, i) in items" :key="`patient-item-${item.patient_idx}`">
                <td class="text-center">
                  <router-link :to="`/patients/${item.patient_idx}`">
                    {{ item.patient_idx }}
                  </router-link>
                </td>
                <td class="text-center">
                  <router-link :to="`/patients/${item.patient_idx}`">
                    {{ item.user_name }}
                  </router-link>
                </td>
                <td class="py-2">
                  <v-chip small outlined class="mr-2" :color="item.user_gender === 'male' ? 'blue' : 'red'">
                    {{ item.user_gender === 'male' ? '남' : '여' }}, {{ item.user_age }}세, {{ item.patient_weight }}Kg
                  </v-chip>
                  <v-chip outlined small v-if="item.patient_location_type_detail_2">
                    {{ item.patient_location_type_detail_2 }}
                  </v-chip>
                  <br />
                  <v-chip label small class="mt-2">
                    {{ item.patient_medical_information.slice(0, 10) }}
                  </v-chip>
                </td>
                <td class="py-2">
                  <v-chip label small class="mr-2">
                    {{ item.patient_more_information }}
                  </v-chip>
                  <v-chip label small class="mr-2">
                    {{ item.patient_want_carer_gender }}
                  </v-chip>
                  <div class="mt-2">
                    <v-chip label small class="mr-2">
                      {{ filterDateFormat(item.patient_started_at) }} ~ {{ filterDateFormat(item.patient_ended_at) }}
                    </v-chip>
                    <v-chip label small class="mr-2">
                      {{ item.patient_care_time_info }}, {{ item.patient_daily_payment }}
                    </v-chip>
                  </div>
                </td>
                <td class="text-center">
                  {{ item.patient_is_matched == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ item.patient_is_admin == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ countApplicants[i] }}
                </td>
                <td class="text-center">
                  {{ item.patient_is_deleted == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ filterDatetimeFormat(item.patient_created_at) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Config from '@/config'
import dayjs from 'dayjs'
// import paginationLib from '../_elements/pagination/paginationLib'
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import PatientList from './model/patientList'

const locations = [
  { text: '전체', value: null },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    const { page, search } = this.$route.query
    return {
      model: new PatientList(),
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: 'Idx', align: 'center' },
        { text: '환자명', align: 'center' },
        { text: '환자정보', align: 'center' },
        { text: '요청정보', align: 'center' },
        { text: '매칭여부', align: 'center' },
        { text: '관리자', align: 'center' },
        { text: '지원자수', align: 'center' },
        { text: '삭제여부', align: 'center' },
        { text: '등록일', align: 'center' }
      ],
      locations,
      search,
      filterLocation: null,
      pagination: {
        page: +page,
        totalVisible: 10,
        length: 1000
      },
      countApplicants: []
    }
  },
  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.fetch(this.pagination.page)
      }
    },
    filterLocation() {
      this.fetch(this.pagination.page)
    }
  },
  mounted() {
    const { page } = this.$route.query
    this.fetch(page)
  },
  methods: {
    async fetch(page = 1, limit = 10) {
      await axios
        .get(`${Config.Api.Service2.url}/patient/pagination`, {
          params: {
            page,
            limit,
            search: this.search,
            filterLocation: this.filterLocation
          }
        })
        .then(data => {
          this.items = data.data.list
          this.pagination.length = Math.round(data.data.count / data.data.list.length)
        })
      await axios
        .get(`${Config.Api.Service2.url}/count-applicant`, {
          params: {
            ids: JSON.stringify(this.items.map(item => item.patient_idx))
          }
        })
        .then(({ data }) => {
          this.countApplicants = data
        })
    },
    filterDateFormat(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD')
    },
    filterDatetimeFormat(dateString) {
      return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss')
    },
    onEnter() {
      this.$router.push({
        path: './patients',
        query: {
          page: 1,
          search: this.search
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
