/* eslint-disable max-classes-per-file */
import { Api, makeGetUrl } from '@/libraries/api'
import { PatientSchema } from './patientSchema'
import paginationLib from '../../_elements/pagination/paginationLib'

const PatientList = class {
  constructor() {
    this.query = ''
    this.filters = {}
    // this.pagination = new Pagination()
    this.pagination = {}
  }

  getFilterQuery() {
    const result = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(this.filters)) {
      result.push(`${key}=${value}`)
    }
    return result.join('|')
  }

  addfilter(k, v) {
    this.filters[k] = v
  }

  async getList() {
    const url = `/admin/patient`
    const params = {
      page: this.pagination.page,
      limit: this.pagination.limit,
      query: this.query,
      filters: this.getFilterQuery()
    }
    const apiResult = await Api().get(makeGetUrl(url, params))
    const { items, pagination } = apiResult.data.data
    return {
      items: items.map((item) => new PatientSchema(item)),
      pagination: paginationLib.paginationMapper(pagination)
    }
  }
}

export default PatientList
